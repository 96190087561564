<template lang="pug">
  v-scrollable(
    updatable
    :bottom-offset="20")
    .insight-detail__description(
      v-if="activeBadge.description")
      h3 {{'ui.labels.description' | translate}}
      span {{ activeBadge.description }}
      .insight-detail__description--requirements(
        v-if="activeBadge.requiredBadges.length")
        h3 {{'ui.labels.requirements' | translate}}
        node-list-item(v-for="badge in activeBadge.requiredBadges"
          :key="badge.id"
          :data="badge")
</template>

<script>
import GradationTabs from '@/components/ui/GradationTabs'
import NodeListItem from '@/components/common/NodeListItem'

export default {
  name: 'BadgeDataDescription',

  components: {
    GradationTabs,
    NodeListItem
  },

  props: {
    activeBadge: Object
  }
}
</script>

<style lang="scss">

  .insight-detail__description {

    h3 {
      line-height: unset;
    }

    display: flex;
    flex-direction: column;
    padding-right: 15px;

    &--requirements {
      padding-top: 1rem;
    }
  }
</style>
